import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Contacto por chat")}</Language>
              <Large to="/">{t("¿Prefieres chatear con nosotros?")}</Large>
              <Para>
                {t(`Click en el enlace a continuación para iniciar un chat por whatsapp`)}
              </Para>
              <a href="https://wa.me/+5218129792723">
                <Chat>{t(`Whatsapp`)}</Chat>
              </a>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("Salud y seguridad")}</Title>
              <Large to="/" left="true">
                {t("Contenidos del tratamiento")}
              </Large>
              <Large left="true" to="/mision">
                {t("Nuestra misión")}
              </Large>
            </Col>
            
          </Row>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Empty />
              <Language>{t("¿Donde nos ubicamos?")}</Language>
              <Para>Colón 64299</Para>
              <Para>Monterrey Centro, NL</Para>
              <Para>México</Para>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("Saber más")}</Title>
              <Large left="true" to="/testimonios">
                {t("Testimonios")}
              </Large>
              <Large left="true" to="/Tienda">
                {t("Tienda")}
              </Large>
              <Large left="true" to="/Blog">
                {t("Blog")}
              </Large>
            </Col>
            
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="allevialogo.svg"
                  aria-label="homepage"
                  width="101px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              <SocialLink
                href="https://www.facebook.com/allevia7/"
                src="facebook.svg"
              />
              
              
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
