const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ['/blog'],
    exact: true,
    component: "Blog"
  },
  {
    path:['/mision'],
    exact: true,
    component: "Mision"
  },
  {
    path:['/testimonios'],
    exact: true,
    component: "Testimonios"
  },
  {
    path:['/tienda'],
    exact: true,
    component: "Tienda"
  },
  {
    path: ['/producto/:id'],
    exact: false,
    component: 'Producto'
  },
  {
    path: ['/intro'],
    exact: true,
    component: "Intro"
  },
  {
    path: ['/confirmation'],
    exact: true,
    component: 'Confirmation'
  },
  {
    path: ['/post/:id'],
    exact: false,
    component: 'Post'
  },
  {
    path:['/categorias/:id'],
    exact: false,
    component: 'Categorias'
  }
];

export default routes;
